import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import logo from './logo512.png';
import LandingPage from '../src/Landing_Page.png';
import GooglePlay from '../src/google-play.png';
import AppleStore from '../src/apple_store.png';
import './App.css';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import CommunityGuidelines from './CommunityGuidelines';
import BrandKit from './Brandkit';
import DisputeResolutionPolicy from './DisputeResolutionPolicy';
import MeetSaladHR from './MeetSaladHR';
import BusyMomSurvey from './BusyMomSurvey';
import Support from './Support';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/community-guidelines" element={<CommunityGuidelines />} />
          <Route path="/brand-kit" element={<BrandKit />} />
          <Route path="/dispute-resolution-policy" element={<DisputeResolutionPolicy />} />
          <Route path="/meet-saladhr" element={<MeetSaladHR />} />
          <Route path="/busy-moms-survey" element={<BusyMomSurvey />} />
          <Route path="/support" element={<Support/>} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

function Home() {
  return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <div className="LandingPage-container">
        <img src={LandingPage} className="LandingPage" alt="Landing Page" />
      </div>
      <div className="store-badges">
        <a href="https://play.google.com/store/apps/details?id=com.saladhrtechnologyltd.heysaladr" target="_blank" rel="noopener noreferrer" className="store-badge">
          <img src={GooglePlay} alt="Google Play" />
        </a>
        <a href=" https://apps.apple.com/us/app/heysalad/id6677057350" target="_blank" rel="noopener noreferrer" className="store-badge">
          <img src={AppleStore} alt="Apple Store" />
        </a>
      </div>
  
      <footer className="footer">
        <p>
          <p>© 2024 SALAD HR TECHNOLOGY LTD </p>
          <br />
          Plexel, Here East, Queen Elizabeth Olympic Park, London, England, E20 3BS | Company Number: 14979493 | Made with 💚 from London, United Kingdom.
        </p>
        <div>
          <a href="/brand-kit">Brand Kit</a> |{' '}
          <a href="/community-guidelines">Community Guidelines</a> |{' '}
          <a href="/dispute-resolution-policy">Dispute Resolution Policy</a> |{' '}
          <a href="/privacy-policy">Privacy Policy</a> |{' '}
          <a href="/terms-of-use">Terms of Use</a> 
        </div>
      </footer>
    </header>
  );
}

export default App;
